import React, { memo, useState } from "react";
// import { useRouter } from "next/router";
import StyledBottomNav from "./styles";
import { BottomNavProps } from "./types";
import { DropdownMenu } from "../DropdownMenu";
import { NotificationDot } from "../NotificationDot";
import { Box } from "../Box";
import BottomNavItem from "../BottomNavItem";

const BottomNav: React.FC<React.PropsWithChildren<BottomNavProps>> = ({
  items = [],
  activeItem = "",
  activeSubItem = "",
  ...props
}) => {
  const [menuOpenByIndex, setMenuOpenByIndex] = useState({});
  const isBottomMenuOpen = Object.values(menuOpenByIndex).some((acc) => acc);
  // const [activeIndex, setActiveIndex] = useState(0);

  // const navItems = [
  //   {
  //     path: "/",
  //     defaultIcon: <ShoppingBasketIcon />,
  //     activeIcon: <ShoppingBasketFilledIcon />,
  //     label: "首页",
  //   },
  //   {
  //     path: "/swap",
  //     defaultIcon: <SwapIcon />,
  //     activeIcon: <SwapFillIcon />,
  //     label: "交易",
  //   },
  //   {
  //     defaultIcon: <EarnIcon />,
  //     activeIcon: <EarnFillIcon />,
  //     label: "游戏",
  //   },
  //   {
  //     path: "/farms",
  //     defaultIcon: <NftIcon />,
  //     activeIcon: <NftFillIcon />,
  //     label: "挖矿",
  //   },
  // ];
  // const router = useRouter();
  // useEffect(() => {
  //   const currentIndex = navItems.findIndex((item) => item.path === router.pathname);
  //   if (currentIndex !== -1) {
  //     setActiveIndex(currentIndex);
  //   }
  // }, [router.pathname]);
  return (
    <>
      {/* {isBottomMenuOpen && <Overlay />} */}
      {/* <StyledBottomNav>
        <div className="nav-bar">
          {navItems.map((item, index) => (
            <div
              className={`nav-item ${activeIndex === index ? "active" : ""}`}
              onClick={() => {
                setActiveIndex(index);
                // router.push(item.path);
                console.log(1111111111);
              }}
            >
              {activeIndex === index ? item.activeIcon : item.defaultIcon}
              <p>{item.label}</p>
            </div>
          ))}
        </div>
      </StyledBottomNav> */}
      <StyledBottomNav justifyContent="space-around" {...props}>
        {items.map(
          (
            { label, items: menuItems, href, icon, fillIcon, showOnMobile = true, showItemsOnMobile = true, disabled },
            index
          ) => {
            const statusColor = menuItems?.find((menuItem) => menuItem.status !== undefined)?.status?.color;
            return (
              showOnMobile && (
                <DropdownMenu
                  key={`${label}#${href}`}
                  items={menuItems}
                  isBottomNav
                  activeItem={activeSubItem}
                  showItemsOnMobile={showItemsOnMobile}
                  setMenuOpenByIndex={setMenuOpenByIndex}
                  index={index}
                  isDisabled={disabled}
                >
                  <Box>
                    <NotificationDot show={!!statusColor} color={statusColor}>
                      <BottomNavItem
                        href={href}
                        disabled={disabled}
                        isActive={href === activeItem}
                        label={label}
                        icon={icon}
                        fillIcon={fillIcon}
                        showItemsOnMobile={showItemsOnMobile}
                      />
                    </NotificationDot>
                  </Box>
                </DropdownMenu>
              )
            );
          }
        )}
      </StyledBottomNav>
    </>
  );
};

export default memo(BottomNav);
