import { EN } from './config/languages'

const publicUrl = 'https://locales.pancakeswap.finance'

export const LS_KEY = 'pancakeswap_language'

export const fetchLocale = async (locale: string) => {
  try {
    // 使用动态导入来加载 JSON 文件
    // 注意：这里的路径取决于你的构建配置
    // 例如，在 webpack 中，你可能需要确保 JSON loader 已经配置好
    const module = await import(`../../../locales/${locale}.json`)
    return module.default
  } catch (error) {
    console.error(`Failed to load locale ${locale}`, error)
    return null
  }
}

export const getLanguageCodeFromLS = () => {
  try {
    const codeFromStorage = localStorage.getItem(LS_KEY)
    return codeFromStorage || EN.locale
  } catch {
    return EN.locale
  }
}
