import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <div style={{ width: 87, height: 32 }}>
      <img src="https://upload.ggg.dog/1725421862/1.png" alt="" />
    </div>
  );
};

export default Icon;
