import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M24.1217 4.97333C24.5967 5.40872 24.6287 6.14671 24.1933 6.62169L22.1583 8.84173L24.1583 10.8417C24.6139 11.2973 24.6139 12.036 24.1583 12.4916C23.7027 12.9472 22.964 12.9472 22.5084 12.4916L20.5801 10.5634L11.36 20.6217L9.63999 19.045L22.4733 5.045C22.9087 4.57002 23.6467 4.53794 24.1217 4.97333ZM3.84171 15.5084C4.29732 15.0528 5.03601 15.0528 5.49162 15.5084L8.99162 19.0084L7.34171 20.6583L3.84171 17.1583C3.3861 16.7027 3.3861 15.964 3.84171 15.5084Z"
        fill="#7645D9"
      />
      <path
        d="M5.34824 12.1498L7.68342 14.485C8.59465 15.3963 10.0721 15.3963 10.9833 14.4851L15.6518 9.81649C16.563 8.90527 16.563 7.42789 15.6518 6.51667L13.3166 4.18149C12.4054 3.27027 10.928 3.27027 10.0168 4.18149L5.34825 8.85001C4.43703 9.76123 4.43703 11.2386 5.34824 12.1498Z"
        fill="#7645D9"
      />
      <path
        d="M12.8333 21C12.8333 22.933 11.2663 24.5 9.33334 24.5C7.40034 24.5 5.83334 22.933 5.83334 21C5.83334 19.067 7.40034 17.5 9.33334 17.5C11.2663 17.5 12.8333 19.067 12.8333 21Z"
        fill="#7645D9"
      />
    </svg>
  );
};

export default Icon;
