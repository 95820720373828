import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M25.6667 11.6667C25.6667 7.80067 22.5327 4.66667 18.6667 4.66667H9.33333C5.46734 4.66667 2.33333 7.80067 2.33333 11.6667V19.3676C2.33333 21.5578 4.10884 23.3333 6.29905 23.3333H6.79344C7.99255 23.3333 9.08874 22.6559 9.625 21.5833C10.1613 20.5108 11.2575 19.8333 12.4566 19.8333H15.5434C16.7425 19.8333 17.8387 20.5108 18.375 21.5833C18.9113 22.6559 20.0075 23.3333 21.2066 23.3333H21.7009C23.8912 23.3333 25.6667 21.5578 25.6667 19.3676V11.6667ZM11.0833 10.5C11.0833 9.85567 10.561 9.33333 9.91667 9.33333C9.27234 9.33333 8.75 9.85567 8.75 10.5V11.6667H7.58333C6.939 11.6667 6.41667 12.189 6.41667 12.8333C6.41667 13.4777 6.939 14 7.58333 14H8.75V15.1667C8.75 15.811 9.27233 16.3333 9.91667 16.3333C10.561 16.3333 11.0833 15.811 11.0833 15.1667V14H12.25C12.8943 14 13.4167 13.4777 13.4167 12.8333C13.4167 12.189 12.8943 11.6667 12.25 11.6667H11.0833V10.5ZM15.75 11.0833C15.75 11.7276 16.2723 12.25 16.9167 12.25C17.561 12.25 18.0833 11.7276 18.0833 11.0833C18.0833 10.439 17.561 9.91664 16.9167 9.91664C16.2723 9.91664 15.75 10.439 15.75 11.0833ZM20.4167 12.25C21.0611 12.25 21.5834 11.7276 21.5834 11.0833C21.5834 10.439 21.0611 9.91664 20.4167 9.91664C19.7724 9.91664 19.2501 10.439 19.2501 11.0833C19.2501 11.7276 19.7724 12.25 20.4167 12.25ZM18.0834 14.5833C18.0834 15.2277 17.561 15.75 16.9167 15.75C16.2724 15.75 15.75 15.2277 15.75 14.5833C15.75 13.939 16.2724 13.4167 16.9167 13.4167C17.561 13.4167 18.0834 13.939 18.0834 14.5833ZM19.25 14.5833C19.25 15.2277 19.7724 15.75 20.4167 15.75C21.061 15.75 21.5834 15.2277 21.5834 14.5833C21.5834 13.939 21.061 13.4167 20.4167 13.4167C19.7724 13.4167 19.25 13.939 19.25 14.5833Z"
        fill="#7645D9"
      />
    </svg>
  );
};

export default Icon;
