import { styled } from "styled-components";
import { Flex } from "../Box";

const StyledBottomNav = styled(Flex)`
  position: fixed;
  bottom: 0px;
  width: 100%;
  height: 47px;
  // padding: 5px 8px;
  background: ${({ theme }) => theme.colors.backgroundAlt};
  border-top: 1px solid ${({ theme }) => theme.colors.cardBorder};
  padding-bottom: env(safe-area-inset-bottom);
  html[data-useragent*="TokenPocket_iOS"] & {
    padding-bottom: 45px;
  }
  z-index: 20;
  .nav-bar {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 10px 0;
  }

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    color: #7d7d7d; /* Default text color */
    transition: color 0.3s ease;
  }

  .nav-item svg {
    width: 24px;
    height: 24px;
    // margin-bottom: 5px;
  }

  .nav-item p {
    margin: 0;
  }

  .nav-item.active p {
    color: #8366ff; /* Text color when active */
  }
`;

export default StyledBottomNav;
