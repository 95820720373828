import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.9125 4.83753C10.6847 4.60972 10.3153 4.60972 10.0875 4.83753L7.00002 7.92505L3.9125 4.83753C3.68469 4.60972 3.31535 4.60972 3.08754 4.83753C2.85974 5.06533 2.85974 5.43468 3.08754 5.66248L6.58754 9.16248C6.81535 9.39029 7.18469 9.39029 7.4125 9.16248L10.9125 5.66248C11.1403 5.43468 11.1403 5.06533 10.9125 4.83753Z" fill="#6F639E" />
    </svg>

  );
};

export default Icon;
