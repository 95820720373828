import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4882 2.6253C18.9921 3.92971 21.5673 7.14241 21.9503 11H17.9791C17.8175 7.11917 16.7254 4.39579 15.4938 2.62743L15.4882 2.6253ZM11.9998 2C8.957 3.44197 8.11101 8.08485 7.97817 11H15.9772C15.8383 7.95156 14.9731 3.64755 11.9998 2ZM8.44603 2.64984C7.22084 4.41872 6.1372 7.13553 5.97629 11H2.04907C2.4297 7.16638 4.97535 3.96966 8.44603 2.64984ZM15.4884 21.3747C18.9924 20.0703 21.5676 16.8576 21.9506 13H17.9794C17.8178 16.8808 16.7257 19.6042 15.4941 21.3726L15.4884 21.3747ZM12 22C8.95724 20.558 8.11125 15.9152 7.97842 13H15.9775C15.8386 16.0484 14.9734 20.3525 12 22ZM8.44628 21.3502C7.22108 19.5813 6.13745 16.8645 5.97654 13H2.04932C2.42994 16.8336 4.97559 20.0303 8.44628 21.3502Z" fill="#6F639E" />
    </svg>

  );
};

export default Icon;
