import * as React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18.501 18.5007C21.929 17.7718 24.5 14.7283 24.5 11.0833C24.5 6.89517 21.1048 3.5 16.9166 3.5C13.2716 3.5 10.2282 6.07095 9.49924 9.49904C6.07149 10.2275 3.5 13.2718 3.5 16.9167C3.5 21.1048 6.89517 24.5 11.0833 24.5C14.7282 24.5 17.7725 21.9285 18.501 18.5007ZM22.1666 11.0833C22.1666 8.18384 19.8161 5.83333 16.9166 5.83333C14.6128 5.83333 12.6548 7.31791 11.9482 9.38211C15.4452 9.77911 18.2209 12.5547 18.6179 16.0518C20.6821 15.3452 22.1666 13.3872 22.1666 11.0833Z"
        fill="#7645D9"
      />
      <path
        d="M25.429 20.0711C25.7459 20.3882 25.7459 20.9021 25.429 21.2192L22.6051 24.043H24.4722C24.9205 24.043 25.284 24.4065 25.284 24.8548C25.284 25.3032 24.9205 25.6667 24.4722 25.6667H20.6452C20.1968 25.6667 19.8333 25.3032 19.8333 24.8548V21.0279C19.8333 20.5794 20.1968 20.2161 20.6452 20.2161C21.0935 20.2161 21.457 20.5794 21.457 21.0279V22.8949L24.2808 20.0711C24.5979 19.7541 25.1119 19.7541 25.429 20.0711Z"
        fill="#7645D9"
      />
      <path
        d="M2.57111 7.92889C2.88815 8.24593 3.40217 8.24593 3.7192 7.92889L6.54301 5.10508V6.97214C6.54301 7.4205 6.90649 7.78396 7.35484 7.78396C7.8032 7.78396 8.16667 7.4205 8.16667 6.97214V3.14516C8.16667 2.92985 8.08113 2.72336 7.92889 2.57111C7.77664 2.41887 7.57015 2.33333 7.35484 2.33333H3.52786C3.07949 2.33333 2.71603 2.69679 2.71603 3.14516C2.71603 3.59351 3.07949 3.95699 3.52786 3.95699H5.39492L2.57111 6.7808C2.25407 7.09783 2.25407 7.61185 2.57111 7.92889Z"
        fill="#7645D9"
      />
    </svg>
  );
};

export default Icon;
