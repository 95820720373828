import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => (
  <svg width="22" height="22" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M24.1217 4.97332C24.5967 5.40871 24.6287 6.14671 24.1933 6.62168L22.1583 8.84172L24.1583 10.8417C24.6139 11.2973 24.6139 12.036 24.1583 12.4916C23.7027 12.9472 22.964 12.9472 22.5084 12.4916L20.5801 10.5634L11.36 20.6217L9.63999 19.045L22.4733 5.04499C22.9087 4.57002 23.6467 4.53793 24.1217 4.97332ZM3.84171 15.5084C4.29732 15.0528 5.03601 15.0528 5.49162 15.5084L8.99162 19.0084L7.34171 20.6583L3.84171 17.1583C3.3861 16.7027 3.3861 15.964 3.84171 15.5084Z"
      fill="#6F639E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.33334 22.1667C9.97767 22.1667 10.5 21.6443 10.5 21C10.5 20.3557 9.97767 19.8333 9.33334 19.8333C8.689 19.8333 8.16667 20.3557 8.16667 21C8.16667 21.6443 8.689 22.1667 9.33334 22.1667ZM9.33334 24.5C11.2663 24.5 12.8333 22.933 12.8333 21C12.8333 19.067 11.2663 17.5 9.33334 17.5C7.40034 17.5 5.83334 19.067 5.83334 21C5.83334 22.933 7.40034 24.5 9.33334 24.5Z"
      fill="#6F639E"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.325 6.17321C10.8693 5.71759 10.1307 5.7176 9.67504 6.17321L7.33986 8.50839C6.88425 8.964 6.88424 9.70269 7.33985 10.1583L8.50837 11.3268C8.96398 11.7825 9.70269 11.7825 10.1583 11.3268L12.4935 8.99163C12.9491 8.53602 12.9491 7.79733 12.4935 7.34172L11.325 6.17321ZM8.02513 4.52329C9.39196 3.15645 11.608 3.15646 12.9749 4.52329L14.1434 5.69181C15.5102 7.05864 15.5102 9.2747 14.1434 10.6415L11.8082 12.9767C10.4414 14.3436 8.22528 14.3436 6.85844 12.9767L5.68992 11.8082C4.3231 10.4414 4.32311 8.2253 5.68994 6.85848L8.02513 4.52329Z"
      fill="#6F639E"
    />
  </svg>
);

export default Icon;
